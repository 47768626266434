import React from 'react'
import { Button } from '../Button';
import Container from '../Container';
import { EasyIntegration, MonitoringProfessionals, IntuitiveSystem } from '../utils/Icons'

const tools = [
  {
    title: 'Easy integration',
    ddata: 'Quickly implement advanced emergency response into your apps, security syetem, and connected devices with a few lines of code.',
    icon: <EasyIntegration />,
  },
  {
    title: 'Highly trained monitoring professionals',
    ddata: 'Access to a highly trained control room and monitoring professionals, processing your incident requests.',
    icon: <MonitoringProfessionals />,
  },
  {
    title: 'Intuitive incident response system',
    ddata: 'Eliminate the need for your users to call you during emergencies. Our technology makes it for us to provide safety services to your users.',
    icon: <IntuitiveSystem />,
  },
]

export default function Request() {
  return (
    <section className="py-24 lg:py-40 px-2">
      <Container className="flex flex-col lg:flex-row items-start">
        <div className="hidden lg:block w-full lg:w-2/5 mr-0">
          <div className="w-auto h-5/6">
            <div className="relative max-w-340px">
              <img src={require('../../assets/images/dev-laptop.webp')} alt="a laptop shot" className="max-w-340px" />
              <div class="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black opacity-10"></div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-3/5">
          <h3 className="text-3xl xl:text-5xl text-black font-extrabold w-full lg:w-10/12 mb-14">
            Easy to integrate APIs for 24/7 emergency response
          </h3>
          {tools.map((tool, index) => (
            <div key={index} className="w-full lg:w-10/12 space-y-1.5 mb-10">
              <div className="flex items-center">
                <div className="w-16 flex items-center justify-center">
                  {tool.icon}
                </div>
                <h4 className="text-base lg:text-xl font-bold">{tool.title}</h4>
              </div>
              <p className="text-base lg:text-lg text-[#4f4f4f] font-light">{tool.ddata}</p>
            </div>
          ))}
          <Button className="mt-4" href="mailto:developer@sety.io">Request for API documentation</Button>
        </div>
      </Container>
    </section>
  )
}

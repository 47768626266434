import ListItem from '../utils/ListItem';
import Check from '../utils/Check';

const points = [
    'We provide real-time accurate location data from an emergency request point to all relevant responders',
    'Our infrastructure monitors emergency incident resolution in real time, providing an unprecedented situation awareness and analysis.',
    'Easy to use and fast integration for partners, and businesses. Our web-based / API solution provides a robust tool for connecting users the Sety certifies safety network.',
]

export default function Platform() {
    return (
        <section className="bg-black lg:flex justify-between items-center xl:py-0 lg:space-x-8 max-w-screen-2xl mx-auto">
            <div className="w-full lg:block lg:w-1/2">
                <div class="relative">
                    <img src={require('../../assets/images/platform.webp')} alt="People walking on the street" class="h-96 lg:h-auto object-cover lg:object-none w-full lg:w-auto" />
                    <div class="absolute inset-0 bg-black opacity-20"></div>
                </div>
            </div>
            <div className="w-full lg:w-3/5 xl:w-1/2 py-16 text-white px-8 lg:px-0 text-left lg:pr-4">
                <h2 className="text-4xl lg:text-6xl font-black mb-6 w-full lg:w-3/4">Our Platform</h2>
                <p className="text-lg lg:text-xl w-full xl:w-3/4 leading-7">We focus on building simple technology for effective response across all emergency types. Most importantly, we build a network of reliable responders to support our technology in creating a protected and safe community.</p>
                <ul className="block mt-14 space-y-10 w-full xl:w-3/4">
                    {points.map((point, index) => (
                        <ListItem key={index}>
                            <div className="flex items-start" key={index}>
                                <div className="p-2 mr-3">
                                    <Check className="saturate-50" />
                                </div>
                                <span className="text-base lg:text-xl text-gray-500">{point}</span>
                            </div>
                        </ListItem>
                    ))}
                </ul>
            </div>
        </section>
    )
}

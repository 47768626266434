// App.jsx or index.jsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home, App as Application, Business, Developers, Partners, Estate } from './pages';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/app" element={<Application />} />
      <Route path="/business" element={<Business />} />
      <Route path="/developers" element={<Developers />} />
      <Route path="/partners" element={<Partners />} />
      <Route path="/estate" element={<Estate />} />
    </Routes>
  );
};

export default App;
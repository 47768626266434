import React from 'react'
import Container from '../Container'
import { ButtonLink } from '../Button';

const socials = [
  { label: 'Twitter', url: 'https://x.com/joinsety' },
  { label: 'Facebook', url: 'https://www.facebook.com/joinsety' },
  { label: 'Instagram', url: 'https://www.instagram.com/sety.app/' },
  { label: 'Youtube', url: 'https://youtube.com/' },
  { label: 'Linkedin', url: 'https://www.linkedin.com/company/sety-llc' },
]

const year = new Date().getFullYear();


export default function Socials() {
  return (
    <footer className="pt-32 bg-footerBg">
      <Container className="pb-18 lg:pb-20">
        <section className="py-32 mb-24 bg-primary text-white max-w-screen-2xl mx-auto rounded-3xl px-8 lg:pl-32 xl:pl-40">
          <h2 className="w-full xl:max-w-screen-md text-4xl lg:text-5xl font-black mb-5">Got questions?</h2>
          <p className="text-lg lg:text-2xl text-gray-350 mb-9">Want to know more about how Sety keeps you safe
            <span className="block my-2 lg:my-0">You can also email our support team at <a href="mailto:help@sety.io?subject=Mail from Our Site">help@sety.io.</a></span>
          </p>
          <ButtonLink color="white" className="rounded-full text-primary" href="mailto:help@sety.io?subject=Mail from Our Site">Contact us</ButtonLink>
        </section>
        <ul className="flex flex-wrap justify-between border-y border-gray-450 lg:px-32 py-7 mb-8">
          {socials.map((social, index) => (
            <li key={index}>
              <a href={social.url} target='_blank' rel="noreferrer" className="text-sm lg:text-base text-gray-600 font-light">{social.label}</a>
            </li>
          ))}
        </ul>
        <div className="w-full lg:w-1/2 mx-auto flex flex-wrap lg:flex-row lg:space-x-4 justify-evenly items-center pb-6">
          <a href="/" className="block text-sm lg:text-base text-gray-600 font-light">Privacy policy</a>
          <a href="/" className="block text-sm lg:text-base text-gray-600 font-light">Terms of use</a>
          <span className="block text-base mt-8 lg:mt-0 text-center text-gray-600 font-light">© {year} Sety. All Right Reseved</span>
        </div>
      </Container>
    </footer>
  )
}
